const template = `
  <div class="alert alert-warning unsupported-browser-alert">
    <div class="browser-container">
        <p>
          Unfortunately, your browser version is no longer supported by Fidelity Private Shares.
          In order to access your Fidelity Private Shares account, please update your browser
          version or try an alternative browser.
        </p>
    </div>
  </div>
`;
const supportedBrowsersRegexp = process.env.SUPPORTED_BROWSERS_REGEXP;

document.addEventListener('DOMContentLoaded', () => {
  if (!supportedBrowsersRegexp.test(navigator.userAgent)) {
    document.body.innerHTML = template;
  }
});
